import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { buyerBid } from "../redux/actions/actions";
import { useNavigate } from "react-router-dom";
import "../CSS/BidDetails.css";
import moment from "moment";
import Footer from "../components/Footer";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function Buyerhomepage() {
  const userdata = useSelector((state) => state.loginreducer);
  const clientId = userdata?.buyerDetails[0]?.OM_CLIENT_ID;
  const [datas, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("authToken");

  function getBids() {
    console.log(clientId);
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/getallbids`,
        {
          clientID: clientId,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        console.log(response);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });

    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getBids, []);
  const dateFilteredData = datas.filter((data) => {
    if (startDate && endDate) {
      return (
        data.OT_RFQ_EXPIRY_DATE >= startDate &&
        data.OT_RFQ_EXPIRY_DATE <= endDate
      );
    } else if (startDate) {
      return data.OT_RFQ_EXPIRY_DATE >= startDate;
    } else if (endDate) {
      return data.OT_RFQ_EXPIRY_DATE <= endDate;
    } else {
      return data;
    }
  });
  function daysRemaining(data) {
    const expiryDate = new Date(data);
    const curDate = new Date();
    const timeDiff = expiryDate.getTime() - curDate.getTime();
    var dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 0) {
      dayDiff = 0;
    } else {
      return dayDiff;
    }
    return dayDiff;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <BuyerNavbar />
      <div
        style={{
          position: "relative",
          top: "55px",
        }}
      >
        <div className="header">List of Bids Received</div>
        <div className="Date_filter">
          <span className="Start_date">Start Date:</span>
          <input
            className="start_input"
            type="date"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          ></input>
          <span className="End_Date">End Date:</span>
          <input
            className="start_input"
            type="date"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          ></input>
        </div>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center" }}>BID ID</th>
                <th style={{ textAlign: "start", width: "250px" }}>
                  NARRATION
                </th>
                <th style={{ textAlign: "center" }}>BID DATE</th>
                <th style={{ textAlign: "center" }}>EXPIRY DATE</th>
                <th>DAYS LEFT</th>
                <th>SELLER</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            {dateFilteredData.length ? (
              <tbody className="Info">
                {dateFilteredData.map((data) => (
                  <tr className="table-row" key={data}>
                    <td style={{ textAlign: "center" }}>{data.OT_RFQ_ID}</td>
                    <td style={{ textAlign: "start" }}>
                      {data.OT_RFQ_NARRATION}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_EXPIRY_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {daysRemaining(data.OT_RFQ_EXPIRY_DATE)}
                    </td>
                    <td>{data.OT_RFQ_APPROVED_BY}</td>
                    <td>
                      <button
                        className="View_detials"
                        onClick={() => {
                          dispatch(
                            buyerBid([
                              {
                                RFQID: data.OT_RFQ_ID,
                                SUPPLIERID: data.SUPPLIERID,
                                CLIENTID: clientId,
                              },
                            ])
                          );
                          navigate("/detailsofbid");
                        }}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <br></br>
                <br></br>
                <tr>
                  <td
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    colSpan={7}
                  >
                    No Bids Received!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Buyerhomepage;
