import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  alreadyApplied,
  logIn,
  logInStatus,
  viewBid,
} from "../redux/actions/actions";
// import "../CSS/BuyerNavbar.css";
import "../CSS/Responsive.css";
import { FaBars, FaTimes } from "react-icons/fa";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function Navbar() {
  const myState = useSelector((state) => state.loginreducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosJWT = JwtRequestHandler();
  const BASEURL = process.env.REACT_APP_BASEURL;
  console.log(myState);

  const handleLogOut = () => {
    // axiosJWT.post(`${BASEURL}/logout`, { token: myState[0].refreshToken }, { headers: { authtoken: "Bearer" } }).then((response) => {

    // })
    dispatch(logIn([]));
    dispatch(logInStatus(false));
    dispatch(viewBid(""));
    dispatch(alreadyApplied(""));
    navigate("/");
  };
  const [active, setActive] = useState(true);

  const showNavbar = () => {
    setActive(!active);
  };

  return (
    <header>
      <Link to="/" className="navbar_comapany">
        ZABUNI
      </Link>
      <nav className={`navbar ${active ? "" : "responsive_nav"}`}>
        <Link to="/Listofbids" className="nav_link1">
          Quotations List
        </Link>
        <Link to="/Onlineclients" className="nav_link3">
          Clients
        </Link>
        <Link to="/WonLost" className="nav_link">
          Bid Results
        </Link>
        <Link to="/UnapprovedBids" className="nav_link2">
          Unapproved Bids
        </Link>
        <Link to="/ApprovedBids" className="nav_link2">
          Approved Bids
        </Link>
        <Link to="/registerselleruser" className="nav_link2">
          Add User
        </Link>
        {/* <div className="log">
          Logged In As:{" "}
          {myState.length === 0 ? "" : myState[0].username}
        </div> */}
        <div
          style={{
            fontWeight: 500,
            color: "white",
            display: "block",
            alignItems: "center",
          }}
        >
          <div>Welcome To {myState[0].createdby}</div>
          <div>Logged In As: {myState[0].username}</div>
        </div>
      </nav>
      <button className="log_btn" onClick={handleLogOut}>
        Logout
      </button>
      <button className="nav-btn" onClick={showNavbar}>
        {active ? <FaBars /> : <FaTimes />}
      </button>
    </header>
  );
}

export default Navbar;
