import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { viewBid } from "../redux/actions/actions";
import moment from "moment";
import "../CSS/SaveRfq.css";
import Footer from "../components/Footer";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function MyQuotations() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const userDetails = useSelector((state) => state.loginreducer);
  console.log(userDetails);
  const approvedby = userDetails[0]?.OM_DRIVER_EMAIL?.split("@")[0];
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("authToken");

  function getQuotes() {
    const id = userDetails?.buyerDetails[0]?.OM_CLIENT_ID;
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/myquotations`,
        { id: id },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getQuotes, []);

  function handleApprove(a, b, c, d, e) {
    const rfqid = a;
    const client = b;
    const activedays = c;
    const expiry = d;
    const narration = e;
    const processedby = userDetails[0]?.OM_DRIVER_EMAIL?.split("@")[0];
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/approverfq`,
        {
          rfqid,
          client,
          activedays,
          expiry,
          narration,
          processedby,
          approvedby,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        alert(response.data.message);
        window.location.reload(false);
      });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <BuyerNavbar />
      <div style={{ position: "relative", top: "50px" }}>
        <div className="header">List of Quotations Created</div>
        <div className="overflow_x">
          <div className="container">
            <div className="Search2">
              <div className="products-filter">
                <p>Filter By Results</p>
                <select
                  className="filter-brand"
                  id="resFilter"
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    document.getElementById("statFilter").value = "none";
                    document.getElementById("approFilter").value = "none";
                  }}
                >
                  <option className="option" value={"none"} selected>
                    Select{" "}
                  </option>
                  <option value={"declared"}>Declared</option>
                  <option value={"pending"}>Pending</option>
                </select>
              </div>
              <div>
                <p>Filter By Status</p>
                <select
                  className="filter-brand"
                  id="statFilter"
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    document.getElementById("resFilter").value = "none";
                    document.getElementById("approFilter").value = "none";
                  }}
                >
                  <option value={"none"} selected>
                    Select
                  </option>
                  <option value={"ACTIVE"}>ACTIVE</option>
                  <option value={"NOT ACTIVE"}>NOT ACTIVE</option>
                  <option value={"CLOSED"}>CLOSED</option>
                </select>
              </div>
              <div>
                <p>Filter By Approval</p>
                <select
                  className="filter-brand"
                  id="approFilter"
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    document.getElementById("resFilter").value = "none";
                    document.getElementById("statFilter").value = "none";
                  }}
                >
                  <option value={"none"} selected>
                    Select
                  </option>
                  <option value={1}>APPROVED</option>
                  <option value={0}>NOT APPROVED</option>
                </select>
              </div>
            </div>
            <table>
              <thead>
                <tr className="headerName">
                  <th style={{ textAlign: "center" }}>RFQID</th>
                  <th style={{ width: "250px" }}>NARRATION</th>
                  <th>CREATED ON</th>
                  <th>EXPIRY DATE</th>
                  <th>STATUS</th>
                  <th>IS APPROVED</th>
                  <th>WINNER</th>
                  <th style={{ padding: " 0px 0px 0px 47px" }}> ACTIONS</th>
                </tr>
              </thead>
              <tbody className="Info">
                {data
                  .filter((data) => {
                    if (filterValue === "none" || filterValue === "") {
                      return data;
                    } else if (data.OT_RFQ_STATUS === filterValue) {
                      return data;
                    } else if (filterValue === "declared") {
                      return data.OT_RFQ_WON_BY !== "";
                    } else if (data.OT_RFQ_APPROVED == filterValue) {
                      return data;
                    } else if (filterValue === "pending") {
                      return (
                        data.OT_RFQ_WON_BY == "" || data.OT_RFQ_WON_BY == null
                      );
                    }
                  })
                  .map((data) => (
                    <tr className="table-row" key={data.OT_RFQ_ID}>
                      <td style={{ textAlign: "center" }}>{data.OT_RFQ_ID}</td>
                      <td className="Narration1">{data.OT_RFQ_NARRATION}</td>
                      <td className=" Datee">
                        {moment(data.OT_RFQ_DATE).format("DD-MM-YYYY")}
                      </td>
                      <td className=" Datee">
                        {moment(data.OT_RFQ_EXPIRY_DATE).format("DD-MM-YYYY")}
                      </td>
                      <td>{data.OT_RFQ_STATUS}</td>
                      <td
                        style={{
                          fontWeight: "bold",
                        }}
                        className="approval"
                      >
                        {data.OT_RFQ_APPROVED ? "Yes" : "No"}
                      </td>
                      <td>
                        {data.OT_RFQ_WON_BY ? data.OT_RFQ_WON_BY : "Pending"}
                      </td>
                      <td>
                        <div className="Approval_button">
                          <div>
                            <button
                              className="wid"
                              onClick={() => {
                                dispatch(viewBid(data.OT_RFQ_ID));
                                navigate("/updatequotation", {
                                  state: { isApproved: data.OT_RFQ_APPROVED },
                                });
                              }}
                            >
                              VIEW
                            </button>
                          </div>
                          <div>
                            <button
                              className="wid2"
                              onClick={() => {
                                const confirmBox = window.confirm(
                                  "Are you sure you want to approve?"
                                );
                                if (data.OT_RFQ_APPROVED === true) {
                                  alert("The quotation is already approved.");
                                } else if (confirmBox === true) {
                                  handleApprove(
                                    data.OT_RFQ_ID,
                                    data.OT_RFQ_ID_FROM_CLIENT,
                                    data.OT_RFQ_ACTIVE_DAYS,
                                    data.OT_RFQ_EXPIRY_DATE,
                                    data.OT_RFQ_NARRATION
                                  );
                                }
                              }}
                            >
                              APPROVE
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MyQuotations;
