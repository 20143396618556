import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import Loading from "./Loading";
import moment from "moment";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import { useNavigate } from "react-router-dom";

function WonLost() {
  const [datas, setData] = useState([]);
  const myState = useSelector((state) => state.loginreducer);
  const [isLoading, setIsLoading] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  function winlose() {
    const id = myState[0].id;
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/winlose`,
        {
          id: Number(id),
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(winlose, []);
  function dispMsg(dat) {
    let message;
    const user = myState[0].createdby;
    if (dat === user) {
      message = "Bid WON!";
    } else if (dat === null || dat === "") {
      message = "Pending!";
    } else if (dat !== null && dat !== user) {
      message = "Bid Lost!";
    }
    return message;
  }

  function daysRemaining(data) {
    const expiryDate = new Date(data);
    const curDate = new Date();
    const timeDiff = expiryDate.getTime() - curDate.getTime();
    var dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 0) {
      dayDiff = 0;
    } else {
      return dayDiff;
    }
    return dayDiff;
  }

  const renderWinLost = (
    <div>
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="header">RESULT OF YOUR BIDS</div>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center" }}>BID ID</th>
                <th>BUYER</th>
                <th>NARRATION</th>
                <th>SUBMITTED BY</th>
                <th>CREATED DATE</th>
                <th>EXPIRY DATE</th>
                <th>DAYS PENDING</th>
                <th>STATUS</th>
                <th>RESULTS</th>
              </tr>
            </thead>
            <tbody className="Info">
              {datas.map((data) => {
                return (
                  <tr className="table-row">
                    <td style={{ textAlign: "center" }}>{data.OT_RFQ_ID}</td>
                    <td>{data.OT_RFQ_PROCESSED_BY}</td>
                    <td>{data.OT_RFQ_NARRATION}</td>
                    <td>{data.EMAILADDRESS.split("@").shift()}</td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_EXPIRY_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {daysRemaining(data.OT_RFQ_EXPIRY_DATE)}
                    </td>
                    <td>{data.OT_RFQ_STATUS}</td>
                    <td>{dispMsg(data.OT_RFQ_WON_BY)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderWinLost}</div>;
}

export default WonLost;
