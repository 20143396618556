import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn, logInStatus, logInStatusBuyer } from "../redux/actions/actions";
import "../CSS/Login.css";
import Footer from "./Footer";
import Loading from "./Loading";
import Img from "../Images/login_bg.jpg";
import STAMP from "../Images/STAMP2.png";

function Login() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("authToken");
  const dispatch = useDispatch();
  const loginstate = useSelector((state) => state.loginstatereducer);
  const loginstatebuyer = useSelector((state) => state.loginstateredbuyer);
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showpwd, setShowPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginmode, setLoginmode] = useState("");
  const errRef = useRef();
  const navigate = useNavigate();
  // const [buyerDetails, setBuyerDetais] = useState([]);
  const buyerDetails = useRef([]);
  const handleUsernameChange = (event) => {
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPwd(event.target.value);
  };

  function clear() {
    const sellerstatus = loginstate;
    const buyerstatus = loginstatebuyer;
    if (sellerstatus == true) {
      navigate("/listofbids");
    } else if (buyerstatus == true) {
      navigate("/buyerhomepage");
    }
  }
  useEffect(clear, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, loginmode]);

  // async function getBuyeruserId(email) {
  //   await axios
  //     .get(`${BASEURL}/getBuyeruserId?OM_CLIENT_EMAIL_ADDRESS=${email}`)
  //     .then((response) => {
  //       setBuyerDetais(response?.data);
  //     })
  //     .catch((err) => {
  //       console.log("Request Cancelled.");
  //     });
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowPwd(false);
    setIsLoading(true);
    if (loginmode == "Seller") {
      console.log(user, pwd);
      axios.post(`${BASEURL}/login`, { user, pwd }).then((response) => {
        setIsLoading(true);
        if (response.data.message) {
          setIsLoading(false);
          setErrMsg(response.data.message);
          console.log(response.data.message);
        } else {
          dispatch(logIn(response.data));
          dispatch(logInStatus(true));
          console.log(response.data);
          window.sessionStorage.setItem(
            "authToken",
            response.data[0].authToken
          );
          navigate("/Listofbids");
        }
      });
    } else if (loginmode == "Buyer") {
      console.log(user, pwd);

      // await getBuyeruserId(user);
      axios
        .post(`${BASEURL}/buyerlogin`, { password: pwd, email: user })
        .then(async (response) => {
          setIsLoading(true);
          if (response.data.message) {
            setIsLoading(false);
            setErrMsg(response.data.message);
            console.log(response.data.message);
          } else {
            await axios
              .get(`${BASEURL}/getBuyeruserId?OM_CLIENT_EMAIL_ADDRESS=${user}`)
              .then((response) => {
                buyerDetails.current = response.data;
              })
              .catch((err) => {
                console.log("Request Cancelled.");
              });

            // dispatch(logIn(response.data));
            console.log(buyerDetails.current);
            // dispatch(logIn({ ...response.data, buyerDetails.current }));
            dispatch(
              logIn({ ...response.data, buyerDetails: buyerDetails.current })
            );
            dispatch(logInStatusBuyer(true));
            console.log(response?.data[0]?.authToken);
            navigate("/buyerhomepage");
            window.sessionStorage.setItem(
              "authToken",
              response.data[0].authToken
            );
          }
        });
    } else {
      setIsLoading(false);
      setErrMsg("Please select mode of login!");
    }
  };

  const renderLogin = (
    <div className="login-main-box">
      <div className="name">
        <h2 className="Company_name">ZABUNI</h2>
      </div>
      <div className="To_center_form">
        <p>
          <img className="image_Zabuni" src={Img} alt="img" />
        </p>
        <section className="form">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <p>
            <img className="stamp" src={STAMP} alt="img" />
          </p>
          <h1 className="sign_in">Sign In</h1>

          <form className="form_aliging_size" onSubmit={handleSubmit}>
            <div className="center_label_form">
              <label style={{ marginLeft: "6px " }}>Mode of login:</label>
              <select
                className="for_input"
                onChange={(e) => {
                  setLoginmode(e.target.value);
                }}
              >
                <option>Choose one..</option>
                <option>Seller</option>
                <option>Buyer</option>
              </select>
              <div>
                <label className="for_lable_form" htmlFor="user_name">
                  Email:
                </label>
                <input
                  className="for_login_input"
                  type="text"
                  id="username"
                  autoComplete="off"
                  placeholder="example@mail.com"
                  onChange={handleUsernameChange}
                  value={user}
                  required
                />
              </div>

              <label className="for_lable_form" htmlFor="user_name">
                Password:
              </label>
              <input
                className="for_login_input"
                type={showpwd ? "text" : "password"}
                id="password"
                placeholder="Password"
                onChange={handlePasswordChange}
                value={pwd}
                required
              />
              <div className="ptagg">
                <input
                  type="checkbox"
                  onClick={() => {
                    setShowPwd(!showpwd);
                  }}
                ></input>
                <span className="space">Show Password</span>
              </div>
              <div className="to_center_pass_btn">
                <button className="sign">Sign In</button>
                <p className="ptag">
                  <p className="tag">
                    <Link
                      to="/Registerbuyer"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Register as Buyer
                    </Link>
                  </p>
                  <p className="tag">
                    <Link
                      to="/registerseller"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Register as Supplier
                    </Link>
                  </p>
                  <p className="line">
                    <Link
                      to="/ForgotPwd"
                      className="click"
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      Forgot Password?
                    </Link>
                  </p>
                </p>
              </div>
            </div>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderLogin}</div>;
}

export default Login;
