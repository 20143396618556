import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import JwtRequestHandler from "../utils/JwtRequestHandler";

const ApprovedBids = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [datas, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state) => state.loginreducer);
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("authToken");

  function secur() {
    const supplierid = userDetails[0].id;
    // const source = axios.CancelToken.source()
    axios
      .post(
        `${BASEURL}/approvedbids`,
        { supplierid: Number(supplierid) },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(secur, []);

  function daysRemaining(data) {
    const expiryDate = new Date(data);
    const curDate = new Date();
    const timeDiff = expiryDate.getTime() - curDate.getTime();
    var dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 0) {
      dayDiff = 0;
    } else {
      return dayDiff;
    }
    return dayDiff;
  }

  const renderApproved = (
    <div>
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <h2 className="header">LIST OF APPROVED BIDS</h2>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center", width: "80px" }}>BID ID</th>
                <th>BUYER</th>
                <th>NARRATION</th>
                <th>SUBMITTED BY</th>
                <th>CREATED DATE</th>
                <th>EXPIRY DATE</th>
                <th>DAYS PENDING</th>
                <th>STATUS</th>
                <th>APPROVED BY</th>
              </tr>
            </thead>
            <tbody className="Info">
              {datas.map((data) => {
                return (
                  <tr className="table-row">
                    <td style={{ textAlign: "center" }}>{data.OT_RFQ_ID}</td>
                    <td>{data.OT_RFQ_PROCESSED_BY}</td>
                    <td className="Narration">{data.OT_RFQ_NARRATION}</td>
                    <td>{data.EMAILADDRESS.split("@").shift()}</td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(data.OT_RFQ_EXPIRY_DATE).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {daysRemaining(data.OT_RFQ_EXPIRY_DATE)}
                    </td>
                    <td style={{}}>{data.OT_RFQ_STATUS}</td>
                    <td>{data.OT_RFQ_APPROVED_BY}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderApproved}</div>;
};

export default ApprovedBids;
