import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Loading from "./Loading";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function UpdateSellerBid() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const myState = useSelector((state) => state.loginreducer);
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  const bidId = useSelector((state) => state.bidreducer);
  const [isLoading, setIsLoading] = useState(false);
  const [showCalc, setShowCalc] = useState(false);
  const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("authToken");

  function Biddet() {
    const idBid = bidId;
    const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/viewforsellerbidupdate`,
        {
          rfqid: idBid,
          sellerId: myState[0].id,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        const processedData = processData(response.data);
        setDatas(processedData);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        //  else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }

  useEffect(Biddet, []);

  const processData = (data) => {
    const processedArray = data.map((item) => ({
      ITEMCODE: item.OT_RFQ_ITEM_CODE,
      ITEMNAME: item.OT_RFQ_ITEM_NAME,
      RFQID: item.OT_RFQ_ID,
      QUANTITY: item.OT_RFQ_ITEM_QTY_REQUIRED,
      DESC: item.OT_RFQ_ITEM_TECHNICAL_SPECS,
      EMAILID: item.EMAILADDRESS,
      SUPPLIERID: item.SUPPLIERID,
      UPDATEDBY: myState[0].username,
      IMG: item.OT_RFQ_IMAGE_REQUIRED,
      CHARGEDAMT: item.OT_RFQ_ITEM_RATE_CHARGED,
      VATAMT: item.VATAMOUNT,
      CHARGEDAMTINCLUSIVE: item.AMOUNTINCLUSIVEVAT,
      // VATPERC: Math.ceil((Number(100 * Number(item.AMOUNTINCLUSIVEVAT) - 100 * (Number(item.OT_RFQ_ITEM_RATE_CHARGED)))) / Number(item.OT_RFQ_ITEM_RATE_CHARGED))
      VATPERC:
        (Number(item.VATAMOUNT) / Number(item.OT_RFQ_ITEM_RATE_CHARGED)) * 100,
    }));
    return processedArray;
  };

  function calcAmount(array) {
    const amount = array.reduce(
      (total, current) => Number(total) + Number(current.CHARGEDAMT),
      0
    );
    return amount;
  }

  function calcVatamt(array) {
    const vatamt = array.reduce(
      (total, current) => Number(total) + Number(current.VATAMT),
      0
    );
    return vatamt;
  }

  function calcGrandTotal(array) {
    const grandtot = array.reduce(
      (total, current) => Number(total) + Number(current.CHARGEDAMTINCLUSIVE),
      0
    );
    return grandtot;
  }

  const handleFinalSubmit = async (e) => {
    if (myState[0].canApprove === false) {
      alert("You do not have the rights to update a bid.");
    } else if (myState[0].canApprove === true) {
      let empty = false;
      for (var i = 0; i < datas.length; i++) {
        if (
          datas[i].CHARGEDAMT == undefined ||
          datas[i].CHARGEDAMT == "" ||
          datas[i].CHARGEDAMT == null ||
          datas[i].CHARGEDAMT == 0
        ) {
          empty = true;
          break;
        }
      }
      if (empty) {
        alert("Please fill in all the values before saving.");
      } else {
        let error = 0;
        for (var i = 0; i < datas.length; i++) {
          setIsLoading(true);
          const res = await axios.post(
            `${BASEURL}/updatesellerbid`,
            { data: datas[i] },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          );
          if (
            res.data.message === "BID NOT APPLIED: Cannot update a closed bid."
          ) {
            error = 1;
            break;
          } else if (res.data.message === "Server Error!") {
            error = 2;
            break;
          }
        }
        setIsLoading(false);
        if (error === 0) {
          alert("Bid Saved and Updated Successfully!");
          navigate("/unapprovedbids");
        } else if (error === 1) {
          alert("BID NOT APPLIED: Cannot update a closed bid.");
          navigate("/unapprovedbids");
        } else if (error === 2) {
          alert("Server Error!");
          navigate("/unapprovedbids");
        }
      }
    }
  };

  const renderBidDetail = (
    <div>
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="header"> QUOTATION DETAILS </div>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center" }}>BID ID</th>
                <th>Item Code</th>
                <th>Item Name</th>
                <th>Qty Requried</th>
                <th>Description</th>
                <th>Image Required</th>
                <th style={{ textAlign: "center" }}>VAT%</th>
                <th style={{ paddingLeft: "10px" }}>Rate (per Item)</th>
                <th style={{ width: "100px" }}>Total Rate Charged</th>
                <th style={{ width: "100px" }}>Total VAT Amount</th>
                <th style={{ width: "150px" }}>Total Amount(in VAT)</th>
              </tr>
            </thead>
            <tbody className="Info">
              {datas.map((data) => (
                <tr className="table-row" key={data.ITEMCODE}>
                  <td style={{ textAlign: "center" }}>{data.RFQID}</td>
                  <td>{data.ITEMCODE}</td>
                  <td style={{ width: "180px" }}>{data.ITEMNAME}</td>
                  <td>{data.QUANTITY}</td>
                  <td>{data.DESC}</td>
                  <td className="tbody">{data.IMG ? "Yes" : "No"}</td>
                  <td style={{ textAlign: "center", width: "100px" }}>
                    {data.VATPERC.toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <input
                      className="rate_value"
                      type="number"
                      name="CHARGEDAMT"
                      min={1}
                      placeholder="Insert Rate Here"
                      defaultValue={data.CHARGEDAMT / data.QUANTITY}
                      onChange={(e) => {
                        setShowCalc(!showCalc);
                        data.CHARGEDAMT = e.target.value * data.QUANTITY;
                        data.CHARGEDAMTINCLUSIVE =
                          Number(e.target.value) * Number(data.QUANTITY) +
                          (Number(data.VATPERC) / 100) *
                            (Number(e.target.value) * Number(data.QUANTITY));
                        data.VATAMT =
                          (Number(data.VATPERC) / 100) *
                          (Number(e.target.value) * Number(data.QUANTITY));
                      }}
                    ></input>
                  </td>
                  <td style={{ textAlign: "center", width: "100px" }}>
                    {data.CHARGEDAMT.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td style={{ textAlign: "center", width: "100px" }}>
                    {data.VATAMT.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "150px",
                      padding: "1%",
                    }}
                  >
                    {data.CHARGEDAMTINCLUSIVE.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <main className="main_bid_price">
            <div className="VAT_D">
              <label className="value">Total Amount Charged:</label>
              <input
                className="Vat-AMT"
                type="text"
                disabled={true}
                value={calcAmount(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />
            </div>
            <div className="VAT_D">
              <label className="value">Total VAT Amount :</label>
              <input
                className="Vat-AMTT1"
                type="text"
                disabled={true}
                value={calcVatamt(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />
            </div>
            <div className="VAT_De">
              <label className="value">Grand Total :</label>
              <input
                className="Vat-AMTT"
                type="text"
                disabled={true}
                value={calcGrandTotal(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />

              <button
                type="submit"
                className="apply_bid"
                onClick={(e) => {
                  const confirmBox = window.confirm(
                    "Are you sure you want to save?"
                  );
                  if (confirmBox) {
                    handleFinalSubmit(e);
                  }
                }}
              >
                Save & Update
              </button>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderBidDetail}</div>;
}

export default UpdateSellerBid;
