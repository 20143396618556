import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../CSS/RegisterbuyerUser.css";
import Navbar from "./Navbar";
import axios from "axios";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function SellerUserRegistration() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState("");
  const [confpass, setConfpass] = useState("");
  const [canapprove, setCanapprove] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [showpass, setShowpass] = useState(false);
  const mystate = useSelector((state) => state.loginreducer);
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("authToken");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleUser = (e) => {
    setUsername(e.target.value);
  };
  const handlePass = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirm = (e) => {
    setConfpass(e.target.value);
  };
  function handleRegister(e) {
    e.preventDefault();
    const userid = mystate[0].id;
    const createdby = mystate[0].createdby;
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
    if (mystate[0].canApprove !== true) {
      alert("You don't have the rights to create a user.");
    } else if (!emailRegex.test(email)) {
      alert("Please enter a valid email address!");
    } else if (!/[a-z]/.test(password)) {
      alert("Password must have atleast one small letter.");
    } else if (!/[A-Z]/.test(password)) {
      alert("Password must have one CAPS letter.");
    } else if (!/\d/.test(password)) {
      alert("Password must have one number.");
    } else if (!/[^a-zA-Z0-9]/.test(password)) {
      alert("Password must have a special character.");
    } else if (!/^.{8,24}$/.test(password)) {
      alert("Password should be 8-24 characters long.");
    } else if (password === confpass) {
      axios
        .post(
          `${BASEURL}/registerselleruser`,
          {
            username,
            email,
            password,
            createdby,
            userid,
            canapprove,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          if (response.data.message === "exist") {
            alert("Account already exists! Please use a different email!");
          } else if (response.data.message === "ok") {
            alert("Registration Successful!");
            navigate("/");
          }
        });
    } else {
      alert("Passwords don't match! Please try again.");
    }
  }
  useEffect(() => {
    setErrMsg("");
  }, [email, password, confpass]);
  return (
    <div className="color">
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="forma">
          <div className="bg">
            <div>
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <div class="ribbon">
                <span class="ribbon5">
                  REGISTRATION PAGE FOR SUPPLIER USERS
                </span>
              </div>
              <form
                onSubmit={(e) => {
                  const confirmBox = window.confirm(
                    "Are you sure you want to submit?"
                  );
                  if (confirmBox === true) {
                    handleRegister(e);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                <div className="line_spacing">
                  <div>
                    <label>Name</label>
                    <div>
                      <input
                        className="input_field"
                        id="name"
                        type="text"
                        onChange={handleUser}
                        required
                      />
                    </div>
                    <label>Email</label>
                    <div>
                      <input
                        className="input_field"
                        id="email"
                        type="email"
                        onChange={handleEmail}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label>Password</label>
                    <div>
                      <input
                        className="input_field"
                        id="password"
                        type={showpass ? "text" : "password"}
                        onChange={handlePass}
                        autoComplete="new-password"
                        required
                      />
                    </div>
                    <label>Confirm password</label>
                    <div>
                      <input
                        className="input_field"
                        id="confirm"
                        type={showpass ? "text" : "password"}
                        onChange={handleConfirm}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div className="dropdown_2">
                      <label
                        style={{ display: "flex", marginLeft: "58px" }}
                        htmlFor="canapprove"
                      >
                        Bid Approval Rights
                      </label>
                      <select
                        style={{
                          width: "90px",
                          border: "none",
                          borderBottom: "1px solid ",
                        }}
                        name="canapprove"
                        // className="input_field"
                        required
                        onChange={(e) => {
                          setCanapprove(e.target.value);
                        }}
                      >
                        <option value={null}>Select</option>
                        <option value={Number(1)}>Yes</option>
                        <option value={Number(0)}>No</option>
                      </select>
                    </div>
                  </div>
                  <div className="left_align">
                    <input
                      style={{ marginLeft: "8px" }}
                      type="checkbox"
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    ></input>
                    <span style={{ marginLeft: "6px" }}>Show Password</span>
                  </div>
                  <div>
                    <button type="submit" className="register1">
                      Create User
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SellerUserRegistration;
