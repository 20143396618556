import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logIn, logInStatusBuyer, buyerBid } from "../redux/actions/actions";
import { useState, useRef } from "react";
import "../CSS/BuyerNavber.css";
import "../CSS/Responsive.css";

import { FaBars, FaTimes } from "react-icons/fa";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function BuyerNavbar() {
  const axiosJWT = JwtRequestHandler();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const userdetails = useSelector((state) => state.loginreducer);
  const userName = userdetails[0].OM_DRIVER_EMAIL.split("@")[0].toUpperCase();
  console.log(userName);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // axiosJWT.post(`${BASEURL}/logout`,{token:userdetails[0].refreshToken},{headers:{authtoken:"Bearer"}}).then((response)=>{
    //   alert(response.data.message)
    // })
    dispatch(logIn([]));
    dispatch(logInStatusBuyer(false));
    dispatch(buyerBid([]));
    navigate("/");
  };
  const [active, setActive] = useState(true);

  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
    setActive(!active);
  };

  return (
    <header>
      <Link to="/buyerhomepage" className="navbar_comapany">
        ZABUNI
      </Link>
      <nav ref={navRef}>
        <Link className="home_Link" to="/buyerhomepage">
          Home
        </Link>
        <Link className="Create_new_user_link" to="/registerbuyeruser">
          Create New User
        </Link>
        <Link to="/newquotation" className="nav_link">
          New RFQ
        </Link>
        <Link to="/myquotations">My RFQs</Link>
        <Link to="/buyerinventory">Inventory</Link>
        <div
          style={{
            fontWeight: 500,
            color: "white",
            display: "block",
            alignItems: "center",
            width: "250px",
            marginLeft: "40px",
          }}
        >
          <div>Welcome To {userdetails[0].OM_MODULE_NAME}</div>
          <div>Logged In As {userName}</div>
        </div>
        <button className="log_btn" onClick={handleLogout}>
          Logout
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        {active === true ? <FaBars /> : <FaTimes />}
      </button>
    </header>
  );
}

export default BuyerNavbar;
// import React, { useEffect, useState, useRef } from "react";
// import { FaBars, FaTimes } from "react-icons/fa";
// import { FiLogOut } from "react-icons/fi";

// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// // import Loading from "../../Components/Loading/Loading";
// import { logIn, logInStatusBuyer, buyerBid } from "../redux/actions/actions";
// import "../CSS/MenuNavbar.css";
// import { MdLogout } from "react-icons/md";
// const BuyerNavbar = () => {
//   const navigate = useNavigate();
//   const [isOpen, setIsOpen] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [ModuleAccess, setModuleAccess] = useState([]);
//   const [openGroup, setOpenGroup] = useState(null);
//   const userdetails = useSelector((state) => state.loginreducer);
//   console.log(userdetails);
//   const authToken = window.sessionStorage.getItem("authToken");
//   const BASEURL = process.env.REACT_APP_BASEURL;
//   // const [isloading, setIsLoading] = useState(false);
//   // const authToken = window.sessionStorage.getItem("authToken");
//   const navbarRef = useRef(null);
//   const dispatch = useDispatch();

//   console.log(userdetails.OM_DRIVER_EMAIL);
//   const email = userdetails?.user?.OM_DRIVER_EMAIL;
//   const modulename = userdetails?.OM_MODULE_NAME;
//   const scrollToSection = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   useEffect(() => {
//     AuthorizseUserLogin();
//   }, []);
//   useEffect(() => {
//     scrollToSection();

//     const handleOutsideClick = (event) => {
//       if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//         // Click occurred outside of navbar, close it
//         setIsOpen(false);
//         setIsMenuOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleOutsideClick);
//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, []);

//   const toggleNavbar = () => {
//     setIsOpen(!isOpen);
//   };

//   const logOut = () => {
//     dispatch(logIn([]));
//     navigate("/");
//   };

//   const handleCategory = (category) => {
//     setSelectedCategory((prevCategory) =>
//       prevCategory === category ? null : category
//     );
//   };
//   function AuthorizseUserLogin() {
//     // setIsLoading(true);
//     console.log(email);
//     axios
//       .post(
//         `${BASEURL}/getuserrights`,
//         {
//           EMAIL: email,
//           MODULENAME: modulename,
//         },
//         {
//           headers: {
//             "auth-token": authToken,
//           },
//         }
//       )
//       .then((response) => {
//         console.log(response.data);
//         setModuleAccess(response.data);
//         // console.log(response.data.recordsets[0], "setModuleAccess");
//         // setIsLoading(false);
//       });
//   }
//   useEffect(() => {
//     AuthorizseUserLogin();
//   }, []);

//   const handleGroupClick = (index) => {
//     setOpenGroup(openGroup === index ? null : index);
//   };
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//     // setIsDeliveryNoteClicked(false);
//   };
//   const handleNavigation = (page) => {
//     console.log(page);
//     navigate(`/${page.link}`, { state: { menu: page.menu } });
//   };
//   // if (isloading) {
//   //   return <Loading />;
//   // }
//   return (
//     <nav ref={navbarRef} className="centernav uppercase">
//       <div className="navbar-container">
//         <nav className="navbar">
//           <div className="">
//             <div className="fabar" onClick={toggleMenu}>
//               {isMenuOpen ? <FaTimes /> : <FaBars />}
//             </div>
//           </div>
//           <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
//             <aside>
//               {ModuleAccess.reduce((acc, item, index) => {
//                 // Check if MENU_TYPE already exists in the accumulator
//                 const existingType = acc.find(
//                   (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
//                 );

//                 if (existingType) {
//                   // If MENU_TYPE exists, add the current ID to its list
//                   if (typeof item === "object") {
//                     existingType.ids.push({
//                       name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
//                       link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
//                       menu: item.OM_ACCOUNT_MODULE_MENU,
//                     });
//                     existingType.weblink.push(item.OM_ACCOUNT_MODULE_WEB_PAGE);
//                   } else {
//                     existingType.ids.push(item);
//                     existingType.weblink.push(existingType.weblink[index]);
//                   }
//                 } else {
//                   // If MENU_TYPE doesn't exist, create a new group with the current ID
//                   const newId =
//                     typeof item === "object"
//                       ? {
//                           name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
//                           link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
//                           menu: item.OM_ACCOUNT_MODULE_MENU,
//                         }
//                       : item;

//                   acc.push({
//                     type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
//                     ids: [newId],
//                     weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
//                   });
//                 }
//                 console.log(existingType);
//                 return acc;
//               }, []).map((group, index) => (
//                 <div key={index} style={{ fontSize: "14px" }}>
//                   <p
//                     style={{
//                       cursor: "pointer",
//                       paddingBottom: "0px",
//                       marginBottom: "0px",
//                     }}
//                     onClick={() => handleGroupClick(index)}
//                   >
//                     {group.type}
//                   </p>

//                   {openGroup === index && (
//                     <ul style={{ listStyleType: "none", paddingLeft: "15px" }}>
//                       {group.ids.map((id, idIndex) => (
//                         <li key={idIndex} style={{ cursor: "pointer" }}>
//                           {typeof id === "object" && (
//                             <span
//                               style={{
//                                 textDecoration: "none",
//                                 color: "white",
//                                 cursor: "pointer",
//                               }}
//                               onClick={() => handleNavigation(id)}
//                             >
//                               {id.name}
//                             </span>
//                           )}
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </div>
//               ))}
//             </aside>{" "}
//           </ul>
//         </nav>
//         <div className="w-[85%] flex justify-between">
//           <h4 className="hover-underline-animation  hidden lg:block dashboard name__head__big__screen">
//             PROPERTY MANAGEMENT
//           </h4>
//           <ul className={`navbar_links ${isOpen ? "active" : ""}`}>
//             <h2 className="hover-underline-animation ">
//               &nbsp;Logged in as :{" "}
//               <span style={{ textTransform: "capitalize" }}>
//                 {" "}
//                 {/* {state?.user ? state?.user?.emailId?.split("@")[0] : "N/A"} */}
//               </span>
//             </h2>
//           </ul>
//         </div>

//         <aside>
//           <button onClick={logOut} className="button__logout__navbar">
//             {" "}
//             LOGOUT
//           </button>
//         </aside>

//         <div className="logout__fullscren">
//           <button onClick={logOut} className="log__out__icon">
//             {" "}
//             <MdLogout />
//           </button>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default BuyerNavbar;
