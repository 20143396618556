import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function BuyerUsers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [datas, setData] = useState([]);
  const [showpwd, setShowPwd] = useState(false);
  const user = useSelector((state) => state.loginreducer);
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("authToken");

  function getUsers() {
    const buyer = user[0]?.OM_DRIVER_EMAIL?.split("@")[0];
    // const source = axios.CancelToken.source()
    axios
      .post(
        `${BASEURL}/buyeruserslist`,
        { buyer: buyer },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }

  useEffect(getUsers, []);
  const handleUpdateButton = () => {};

  return (
    <div>
      <BuyerNavbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="tabl">
          The users attached to your account are listed below:
        </div>
        <table>
          <thead>
            <tr>
              <th>User Id</th>
              <th>User Name</th>
              <th>User Email</th>
              <th>Password</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {datas.map((data) => (
              <tr key={data}>
                <td>{data.OM_CLIENT_ID}</td>
                <td>{data.OM_CLIENT_USER_NAME}</td>
                <td>{data.OM_CLIENT_USER_EMAIL}</td>
                <td
                  style={
                    showpwd
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                >
                  {data.OM_CLIENT_USER_PASSWORD}
                </td>
                <td>
                  <button onClick={() => {}}>Edit User</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <>
          <input
            type="checkbox"
            onChange={() => {
              setShowPwd(!showpwd);
            }}
          ></input>
          <span>View Passwords</span>
        </>
      </div>
    </div>
  );
}

export default BuyerUsers;
