import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/Register.css";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { Country, City } from "country-state-city";
import Select from "react-select";

const RegisterBuyer = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [businessline, setBusinessline] = useState("");
  const [buyerpin, setBuyerpin] = useState();
  const [buyercredlim, setBuyercredlim] = useState();
  const [buyercreddays, setBuyercreddays] = useState();
  const [buyertele, setBuyertele] = useState();
  const [password, setPassword] = useState("");
  const [confpass, setConfpass] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showpass, setShowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneCode, setPhoneCode] = useState();
  const [phoneBody, setPhoneBody] = useState();
  const authToken = window.sessionStorage.getItem("authToken");
  const countries = Country.getAllCountries();
  const [cities, setCities] = useState([]);

  const handleCountry = (e) => {
    const selectedCountry = e.value;
    const cityValues = City.getCitiesOfCountry(selectedCountry.isoCode);
    setCities(cityValues);
    setCountry(selectedCountry.name);
  };

  function handleRegister(e) {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
    if (!emailRegex.test(email)) {
      setErrMsg("Please enter a valid email address!");
    } else if (!/[a-z]/.test(password)) {
      setErrMsg("Password must have atleast one small letter.");
    } else if (!/[A-Z]/.test(password)) {
      setErrMsg("Password must have one CAPS letter.");
    } else if (!/\d/.test(password)) {
      setErrMsg("Password must have one number.");
    } else if (!/[^a-zA-Z0-9]/.test(password)) {
      setErrMsg("Password must have a special character.");
    } else if (!/^.{8,24}$/.test(password)) {
      setErrMsg("Password should be 8-24 characters long.");
    } else if (password === confpass) {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/registerbuyer`,
          {
            user,
            email,
            password,
            country,
            city,
            businessline,
            address,
            buyerpin,
            buyertele,
            buyercredlim,
            buyercreddays,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.message === "exist") {
            setErrMsg("Account already exists! Please use a different email!");
          } else if (response.data.message === "ok") {
            alert("Registration Successful!");
            navigate("/");
          }
        });
    } else {
      setErrMsg("Passwords don't match! Please try again.");
    }
  }
  useEffect(() => {
    setErrMsg("");
  }, [email, password, confpass]);

  if (isLoading) {
    return <Loading />;
  }

  const styleforselect = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid ",
      height: "10px",
      minHeight: "initial",
      height: "30px",
      width: "232px",
    }),
  };
  const styleforselecttelephone = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid ",
      height: "10px",
      minHeight: "initial",
      height: "30px",
      width: "90px",
    }),
  };
  return (
    <div>
      <div className="zab">
        <Link to="/" className="company_nam">
          ZABUNI
        </Link>
      </div>
      <div className="User_register">
        {/* rgb(249, 250, 246); */}

        <div class=" main-center">
          <form
            onSubmit={(e) => {
              const confirmBox = window.confirm(
                "Are you sure you want to submit?"
              );
              if (confirmBox === true) {
                handleRegister(e);
              } else {
                e.preventDefault();
              }
            }}
          >
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

            <div class="ribbon">
              <span class="ribbon7">REGISTRATION PAGE FOR BUYERS</span>
            </div>
            {/* <h1 className="to_center_align"> Registration Page For Buyers</h1> */}
            <div>
              <div className="display_block">
                <div className="display_flex">
                  <div>
                    <label>Company Name</label>
                    <div>
                      <input
                        className="form-group"
                        id="name"
                        type="text"
                        onChange={(e) => {
                          setUser(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    {/* <div>
                      <label className="label">Email </label>
                    </div> */}
                    <label>Email </label>
                    <div>
                      <input
                        className="form-group"
                        id="email"
                        type="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="display_flex">
                  <div>
                    {/* <label className="label" htmlFor="businessline">
                      Business Line
                    </label> */}
                    <label>Business Line</label>
                    <div>
                      <input
                        className="form-group"
                        id="businessline"
                        type="text"
                        onChange={(e) => {
                          setBusinessline(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    {/* <div>
                      <label className="label" htmlFor="address">
                        Address
                      </label>
                    </div> */}
                    <label>Address</label>
                    <div>
                      <input
                        className="form-group"
                        id="address"
                        type="text"
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="display_flex">
                    <div>
                      {/* <div>
                        <label className="label" htmlFor="pin">
                          Pincode{" "}
                        </label>
                      </div> */}
                      <label>Pincode</label>
                      <div>
                        <input
                          className="form-group"
                          id="pin"
                          type="text"
                          onChange={(e) => {
                            setBuyerpin(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="division">
                      {/* <label className="label" htmlFor="tele">
                        Telephone{" "}
                      </label> */}
                      <label>Telephone</label>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div>
                          <Select
                            id="phonecode"
                            styles={styleforselecttelephone}
                            required
                            options={countries.map((item) => ({
                              label: item.phonecode,
                              value: item.phonecode,
                            }))}
                            onChange={(e) => {
                              setPhoneCode(e.value);
                              setBuyertele("" + e.value + phoneBody);
                            }}
                          />
                        </div>
                        <div>
                          <input
                            style={{ width: "140px" }}
                            className="form-group"
                            id="tele"
                            maxLength={15}
                            type="number"
                            onChange={(e) => {
                              setPhoneBody(e.target.value);
                              setBuyertele("" + phoneCode + e.target.value);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="display_flex">
                  <div>
                    {/* <div>
                      <label className="label" htmlFor="credlimit">
                        Credit Limit{" "}
                      </label>
                    </div> */}
                    <label>Credit Limit</label>
                    <div>
                      <input
                        className="form-group"
                        id="credlimit"
                        type="number"
                        onChange={(e) => {
                          setBuyercredlim(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    {/* <div>
                      <label className="label" htmlFor="creddays">
                        Credit Days{" "}
                      </label>
                    </div> */}
                    <label>Credit Days</label>
                    <div>
                      <input
                        className="form-group"
                        id="creddays"
                        type="number"
                        onChange={(e) => {
                          setBuyercreddays(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="display_flex">
                  <div>
                    {/* <label className="label" htmlFor="country">
                      Country{" "}
                    </label> */}
                    <label>Country</label>
                    <div>
                      {/* <input
                        className="form-group"
                        id="country"

                        type="text"
                        onChange={handleCountry}
                        required
                      /> */}
                      <Select
                        id="country"
                        required
                        styles={styleforselect}
                        onChange={(e) => {
                          handleCountry(e);
                        }}
                        options={countries.map((item) => ({
                          label: item.name,
                          value: item,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="division">
                    {/* <label className="label" htmlFor="city">
                      City
                    </label> */}
                    <label>City</label>
                    <div>
                      {/* <input
                        className="form-group"
                        id="city"

                        type="text"
                        onChange={()=>{}}
                        required
                      /> */}
                      <Select
                        id="city"
                        required
                        styles={styleforselect}
                        onChange={(e) => setCity(e.value)}
                        options={cities.map((item) => ({
                          label: item.name,
                          value: item.name,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="display_flex">
                  <div>
                    {/* <label className="label" htmlFor="password">
                      Password
                    </label> */}
                    <label>Password</label>
                    <div>
                      <input
                        className="form-group"
                        id="password"
                        type={showpass ? "text" : "password"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        autoComplete="new-password"
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    <div>
                      {/* <label className="label" htmlFor="confirm">
                        Confirm Password
                      </label> */}
                      <label>Confirm Password</label>
                      <div>
                        <input
                          className="form-group"
                          id="confirm"
                          type={showpass ? "text" : "password"}
                          onChange={(e) => {
                            setConfpass(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lower">
                  <div>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    ></input>

                    <span className="show_pass">Show Password</span>
                  </div>
                  <div>
                    <button className="register">Register</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* <Footer /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterBuyer;
